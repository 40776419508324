/* latin */
@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Proxima Nova'),
    local('proximanova-regular'),
    url(@kensho/fonts/fonts/proxima-nova-regular.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local('Proxima Nova Bold'),
    local('proximanova-bold'),
    url(@kensho/fonts/fonts/proxima-nova-bold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Proxima Nova';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src:
    local('Proxima Nova Bold Italic'),
    local('proximanova-boldit'),
    url(@kensho/fonts/fonts/proxima-nova-bold-it.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Proxima Nova';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src:
    local('Proxima Nova Light Italic'),
    local('proximanova-lightit'),
    url(@kensho/fonts/fonts/proxima-nova-light-it.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Proxima Nova';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src:
    local('Proxima Nova Italic'),
    local('proximanova-regularit'),
    url(@kensho/fonts/fonts/proxima-nova-regular-it.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
